<div class="form-header login-header" (click)="showMeErrors()">
  <ng-template [ngIf]="!(fromMenu$ | async)">
    <h2>{{ 'pages.authentication.login.lets_login' | translate }}</h2>
    <div>{{ 'pages.authentication.login.please_login' | translate }}</div>
  </ng-template>

  <ng-template [ngIf]="fromMenu$ | async">
    <h2>{{ 'pages.authentication.login.title' | translate }}</h2>
    <div>{{ 'pages.authentication.login.lets_login' | translate }}</div>
  </ng-template>
</div>

<div class="form-subheader email-signin-link">
  {{ 'pages.authentication.login.signup.text' | translate }}
  <span class="subheader-link" (click)="goToEmailSignUp()">
    {{ 'pages.authentication.login.signup.link' | translate }}
  </span>
</div>

<div class="facebook-error-text" *ngIf="facebookSignError$ | async">
  <i class="n2-ico-mask n2-ico-mask__alert"></i> {{ 'pages.authentication.register.facebook_could_signin' | translate }}
</div>
<button class="facebook-btn facebook-button-signin" (click)="doFacebookSignUp()">
  <div class="facebook-icon-wrapper">
    <img src="assets/imgs/facebook-icon.svg" />
  </div>
  <div class="facebook-text">
    {{ 'pages.authentication.signup.continue_facebook' | translate }}
  </div>
</button>

<button class="social-btn google-button-signup" (click)="doGoogleSignUp()">
  <div class="facebook-icon-wrapper">
    <img src="assets/imgs/google-icon.svg" />
  </div>
  <div class="google-text">
    {{ 'pages.authentication.signup.continue_google' | translate }}
  </div>
</button>

<div *ngIf="cordova && isIos">
  <div class="facebook-error-text" *ngIf="appleSignError$ | async">
    <i class="n2-ico-mask n2-ico-mask__alert"></i> {{ 'pages.authentication.register.apple_could_signin' | translate }}
  </div>
  <button class="apple-btn apple-button-signup" (click)="doAppleSignUp()">
    <div class="apple-icon-wrapper">
      <i class="n2-ico-mask n2-ico-mask__logo-apple"></i>
    </div>
    <div class="apple-text">
      {{ 'pages.authentication.signup.continue_apple' | translate }}
    </div>
  </button>
</div>

<div class="or-line login-line">
  <span class="or-text">{{ 'pages.authentication.landing.signin_or' | translate }}</span>
</div>

<form [ngrxFormState]="(formState | async)" (submit)="onSubmit()">

  <div class="main-error-text" *ngIf="(formState | async).userDefinedProperties.serverError">
    <i class="n2-ico-mask n2-ico-mask__alert"></i> {{ 'pages.authentication.login.form.errors.invalid_form' | translate }}
  </div>

  <div class="input-label">{{ 'pages.authentication.login.form.email' | translate }}</div>
  <input (focus)="onFocus($event)" 
    (blur)="onBlur()" 
    type="email" 
    name="email"
    [ngrxFormControlState]="(formState | async).controls.email" 
    [ngrxEnableFocusTracking]="true"
    [ngClass]="{'error': showErrors && (formState | async).controls.email.isInvalid && (formState | async).controls.email.isUnfocused }">

  <div class="error-text"
    *ngIf="showErrors && (formState | async).controls.email.isUnfocused && (formState | async).errors._email">
    <div *ngFor="let error of objectKeys((formState | async).errors._email)">
      {{ 'pages.authentication.login.form.errors.' + error | translate }}
    </div>
  </div>

  <div *ngIf="showLoginErrorsFlag">
    {{ showLoginErrors }}
  </div>

  <div class="input-label">{{ 'pages.authentication.login.form.password' | translate }}</div>
  <input (focus)="onFocus($event)" 
    (blur)="onBlur()" 
    type="password" 
    name="password"
    [ngrxFormControlState]="(formState | async).controls.password" 
    [ngrxEnableFocusTracking]="true"
    [ngClass]="{'error': showErrors && (formState | async).controls.password.isInvalid && (formState | async).controls.password.isUnfocused }">

  <div class="error-text"
    *ngIf="showErrors && (formState | async).controls.password.isUnfocused && (formState | async).errors._password && (formState | async).errors._password">
    <div *ngFor="let error of objectKeys((formState | async).errors._password)">
      {{ 'pages.authentication.login.form.errors.' + error | translate }}
    </div>
  </div>

  <div class="forgot-password-link" (click)="goToPasswordReset()">
    {{ 'pages.authentication.login.forgot_password' | translate }}
  </div>
  <button class="email-signin" type="submit" [disabled]="isDisableSubmit$ | async">
    {{ 'pages.authentication.login.submit' | translate }}
  </button>

</form>