<div class="share-view">
  <div class="share-view__title" *ngIf="showTitle">{{ 'component.share_view.better_together' | translate }}</div>
  <div class="share-view__copy" (click)="copy()">
    <div class="share-view__copy-btn">{{ 'component.share_view.copy' | translate }}</div>
    <div class="share-view__copy-url">{{ shareUrl }}</div>
  </div>
  <div class="share-view__icons">
    <div class="share-view__icon facebook" (click)="openShareLink('Facebook')"></div>
    <div class="share-view__icon messenger" *ngIf="!isIos && cordova" (click)="openShareLink('Messenger')"></div>
    <div class="share-view__icon whatsup" (click)="openShareLink('Whatsapp')"></div>
    <div class="share-view__icon mail" (click)="openShareLink('Email')"></div>
  </div>
</div>