<ion-header class="ion-no-border black-bg">
  <ion-toolbar>
    <div class="gallery-header" [class.is-android]="isAndroid">
      <div class="gallery-header__close" (click)="modalCtrl.dismiss()">
        <i class="n2-ico-mask n2-ico-mask__close"></i>
      </div>
      <div class="gallery-header__share">
        <div appShare
          class="funz-header__sub-btn" 
          (click)="trackFunzShare()" 
          [shareUrls]="(funz$ | async)?.shareUrls"
          [shareUrl]="(funz$ | async)?.shareUrl"
          [shareSource]="'funz'"
          [shareText]="'page.funz.share.message' | translate">
          <i class="n2-ico n2-ico__share-w"></i>
        </div>
      </div>
    </div>
  </ion-toolbar>
</ion-header>
<ion-content class="black-bg">
  <div class="gallery-container">
    <ng-container *ngFor="let media of mediaArray; let index = index">
      <ng-container *ngIf="media.kind === 'picture'">
        <div class="media-file" 
          *ngIf="!media.animated" 
          [ngStyle]="{ 'background': getBackgroundImage(media.image) }"
          (click)="onMediaClick(index)">
        </div>
        <video (click)="onMediaClick(index)" class="media-file" *ngIf="media.animated" autoplay loop muted playsinline
          #video poster="./assets/imgs/transparent-pixel.png" (canplay)="video.play()"
          (loadedmetadata)="video.muted = true">
          <source type="video/mp4" [src]="media.image">
        </video>
      </ng-container>
      <div *ngIf="media.kind === 'video'" (click)="onMediaClick(index)" class="media-file">
        <iframe class="funz-video-slide" [src]="galleryVideosUrls[media.video_id]" 
          autohide="1" controls="0" showinfo="0" frameborder="0" z-index="0">
        </iframe>
      </div>
      
    </ng-container>
  </div>
</ion-content>