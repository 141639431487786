<ion-grid class="order-details card-bg" [ngClass]="{ 'is-slide' : !full }">
  <ion-row>
    <ion-col class="detail-icon" size="2">
      <i class="n2-ico n2-ico__tickets-or"></i>
    </ion-col>
    <ion-col class="detail-text" size="10">
      {{ order.tickets_quantity }} {{ 'component.order.order-details.ticket' 
      | pluralPhrase : { count: order.adults_quantity + order.kids_quantity } | translate }} 
      | {{ order.total_price }}
      <span class="charged">
        ({{ 'components.order.order-details.charged' | translate }} {{ order.price_paid_with_money }})
      </span>
    </ion-col>
  </ion-row>

  <ion-row *ngIf="full">
    <ion-col class="detail-icon" size="2">
      <i class="n2-ico n2-ico__clock-or"></i>
    </ion-col>
    <ion-col class="detail-text" size="10">
    {{ order.start_time | localizeDate:'H:mm' }} - {{ order.end_time | localizeDate:'H:mm' }}
    </ion-col>
  </ion-row>

  <ion-row *ngIf="order.nearest_tube_station && !liveFunz && !order.online">
    <ion-col class="detail-icon" size="2">
      <img src="assets/imgs/n_tube.svg" />
    </ion-col>
    <ion-col class="detail-text detail-tube" size="10">
      {{ 'component.order.order-details.closest_tube_stations' | translate }} {{ order.nearest_tube_station }}
    </ion-col>
  </ion-row>
  <ion-row *ngIf="!liveFunz">
    <ion-col class="detail-icon" size="2">
      <i class="n2-ico n2-ico__point-or"></i>
    </ion-col>
    <ion-col class="detail-text detail-address" size="10">
      <section class="detail-meta">
        <span *ngIf="!order.online">{{ order.address }}</span>
        <br *ngIf="!order.online">
        {{ 'component.order.order-details.directions' | translate }}
        <br><span [class.cut]="!full" [innerHTML]="order.driving_directions"></span>
        <br>{{ 'component.order.order-details.phone_number' | translate }} {{ order.owner.phone }}
      </section>
      <div class="read-more" *ngIf="moreLink">{{ 'component.order.order-details.read_more' | translate }}</div>
    </ion-col>
  </ion-row>
</ion-grid>
