<ng-container *ngIf="stars && stars.score !== '0.0'">
  <i class="n2-ico-mask n2-ico-mask__star" *ngFor="let fullStar of fullStars"></i>
  <i class="n2-ico-mask n2-ico-mask__star-half" *ngIf="stars.half_star"></i>
  <i class="n2-ico-mask n2-ico-mask__star-stroke" *ngFor="let emptyStar of emptyStars"></i>
  <span class="score">{{ stars.score }}</span>
  <span class="feedback-count" *ngIf="feedbackCount">
    ({{ noReviewsSign
      ? feedbackCount
      : 'pages.funz_show.feedbacks_title' | pluralPhrase
        : { count: feedbackCount } | translate
        : { feedbacks: feedbackCount }
    }})
  </span>
</ng-container>
