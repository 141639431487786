<div class="funz-box" 
  (touchstart)="playVideo($event)"
  (click)="navigateToFunzPage()" 
  [ngClass]="{ 'wide': isWide }">
  <div class="funz-box__image">
    <div class="funz-box__image__bg" [style.background-image]="'url(' + srcUrl + ')'"></div>
    <!-- <div class="funz-box__image__bg" *ngIf="isVideo">
      <video loop muted playsinline #video>
        <source type="video/mp4" [src]="srcUrl">
      </video>
    </div> -->
    <!-- <div class="funz-box__price" *ngIf="!funz.liveFunz && funz.startingPrice">
      <div>{{ 'component.funz_box.starting_price' | translate }} {{ funz.startingPrice }}</div>
    </div>
    <div class="funz-box__price" *ngIf="!funz.liveFunz && !funz.startingPrice && canShowPrice">
      <div>{{ maxPrice(funz) }}</div>
    </div> -->
    <div class="funz-box__originals" *ngIf="funz.funzingHost"></div>
    <appAdminStats [adminStats]="funz.adminStats"></appAdminStats>
    <div class="funz-box__wishlist" appRequireAuth
      *ngIf="showHeart"
      (click)="heartClick($event)"
      [authRedirect]="redirectPage"
      [redirectParams]="redirectParams"
      (authenticated)="handleAuthenticated(funz, $event);">
      <i class="n2-ico n2-ico__{{ wishlistIcon === 'custom-wishlist-f' ? 'heart-filled-w' : 'heart-w' }}"></i>
    </div>
  </div>
  <div class="funz-box__city">{{ !funz.online ? funz.city : 'component.location.online' | translate }}</div>
  <div class="funz-box__title-box">
    <div class="funz-box__title">{{ funz.title }}</div>
    <div class="funz-box__price__from" *ngIf="!funz.liveFunz && funz.startingPrice">
      <div>{{ 'pages.funz_show.starting_price' | translate }}
        <span class="price_container">
          {{ funz.startingPrice }}
        </span>
      </div>
    </div>
    <div class="funz-box__price__from" *ngIf="!funz.liveFunz && !funz.startingPrice">
      <div>{{ 'pages.funz_show.starting_price' | translate }}
        <span class="price_container">
          {{ maxPrice(funz) }}
        </span>
      </div>
    </div>
    <div class="funz-box__rating">
      <funz-stars *ngIf="!funz.liveFunz && ghs.canShowStars(funz.displayedStars)"
        [stars]="funz.displayedStars"
        [feedbackCount]="funz.displayedFeedbackCount">
      </funz-stars>
    </div>
  </div>
  <appAdminParticipantsStats [adminParticipantsStats]="funz.adminParticipantsStats"></appAdminParticipantsStats>
  <div class="funz-box__info">
    <div *ngIf="!funz.liveFunz" class="funz-box__info__left">
      <div *ngIf="funz.numberOfUpcomingDates > 0">
        <img class="cal-image" src="assets/imgs/calendar-icon.png" />
      </div>
      <div class="funz-box__today" *ngIf="isToday && funz.nextEventWithTicketsLeft && funz.numberOfUpcomingDates == 1" [class.sold-out]="funz.ticketsLeft === 0">
        {{ 'component.funz_box.today' | translate }}
      </div>
      <div *ngIf="isToday && funz.nextEventWithTicketsLeft && funz.numberOfUpcomingDates > 1" [class.sold-out]="funz.ticketsLeft === 0">
        {{ 'component.funz_box.today' | translate }}
      </div>
      <div *ngIf="!isToday && funz.nextEventWithTicketsLeft" [class.sold-out]="funz.ticketsLeft === 0">
        {{ funz.nextEventWithTicketsLeft | funzBoxDate }}
      </div>
      <div *ngIf="funz.secondNextEventDate">
        ,&nbsp;{{ funz.secondNextEventDate | funzBoxDate }}
      </div>
      <div *ngIf="funz.numberOfUpcomingDates > 2">
        &nbsp;...
      </div>
      <div *ngIf="funz.numberOfUpcomingDates == 0">
        {{ 'component.funz_box.no_available_dates' | translate }}
      </div>
    </div>
    <div *ngIf="funz.liveFunz && funz.liveEventComingDays > -1 && liveDays" class="funz-box__info__left">
      {{ 'live_onlive_message_funz_boz' | translate: {day: liveDays} }} {{ liveTime }}
    </div>
    <div *ngIf="funz.currentLiveEventId && (funz.liveEventComingDays < 0 || funz.liveEventComingDays === null)" class="funz-box__info__left">
      {{ 'live_vod_message' | translate }}
    </div>
    <div class="funz-box__info__right">
      <div class="funz-box__new" *ngIf="funz.isNew && !soldOut && !showTicketsLeft">
        {{ 'component.funz_box.new' | translate }}
      </div>
      <div class="funz-box__tickets" *ngIf="showTicketsLeft && !funz.secondNextEventDate">
        {{ 'component.funz_box.tickets_left' | pluralPhrase : { count: funz.ticketsLeft } | translate : { tickets: funz.ticketsLeft } }}
      </div>
      <div class="funz-box__sold-out" *ngIf="soldOut && !funz.liveFunz">
        {{ 'component.funz_box.sold_out' | translate }}
      </div>
    </div>
  </div>
</div>
