<ion-header class="ion-no-border black-bg">
  <ion-toolbar>
    <div class="gallery-header" [class.is-android]="isAndroid">
      <div class="gallery-header__close" (click)="modalCtrl.dismiss()">
        <i class="n2-ico-mask n2-ico-mask__close"></i>
      </div>
      <div class="gallery-header__title" *ngIf="mediaArray.length === 1">1</div>
      <div class="gallery-header__title" *ngIf="displaySelectedIndex">{{ selectedSlideIndex + 1 }} / {{ mediaArray.length }}</div>
      <div class="gallery-header__share">
        <div class="funz-header__sub-btn" appShare
          (click)="trackFunzShare()" 
          [shareUrls]="(funz$ | async)?.shareUrls"
          [shareUrl]="(funz$ | async)?.shareUrl"
          [shareSource]="'funz'"
          [shareText]="'page.funz.share.message' | translate">
          <i class="n2-ico n2-ico__share-w"></i>
        </div>
      </div>
    </div>
  </ion-toolbar>
</ion-header>
<ion-content class="black-bg">
  <ion-slides
    *ngIf="mediaArray?.length" 
    class="slider-wrapper"
    zoom="true" 
    [pager]="mediaArray.length > 1"
    [options]="slideOpts"
    (ionSlideDidChange)="slideChanged()">
    <ion-slide *ngFor="let media of mediaArray">
      <ng-container *ngIf="media.kind === 'picture'">
        <div *ngIf="!media.animated" class="swiper-zoom-container">
          <img [src]="media.image" class="slider-image" />
        </div>
        <div class="swiper-zoom-container" *ngIf="media.animated">
          <video autoplay loop muted playsinline #video poster="./assets/imgs/transparent-pixel.png"
            (canplay)="video.play()" (loadedmetadata)="video.muted = true">
            <source type="video/mp4" [src]="media.image">
          </video>
        </div>
      </ng-container>
      <div class="funz-video-slide-wrapper" *ngIf="media.kind === 'video'">
        <div class="funz-video-slide-wrapper__top"></div>
        <div class="funz-video-slide-wrapper__left"></div>
        <div class="funz-video-slide-wrapper__right"></div>
        <div class="funz-video-slide-wrapper__bottom"></div>
        <iframe class="funz-video-slide" width="100%" height="430px" z-index="0"
          [src]="galleryVideosUrls[media.video_id]" frameborder="0" allowfullscreen>
        </iframe>
      </div>
    </ion-slide>
  </ion-slides>
</ion-content>