<div class="forgot-password-container">
  <ng-template [ngIf]="!isSent">
    <div class="form-header">
      <h2>{{ 'pages.authentication.forgot_password.title' | translate }}</h2>
      <div>{{ 'pages.authentication.forgot_password.subtitle' | translate }}</div>
    </div>
    <form [ngrxFormState]="(formState | async)" (submit)="onSubmit()">
      <div class="error-text" *ngIf="(formState | async).controls.email.isDirty && (formState | async).controls.email.isUnfocused && (formState | async).errors._email">
        <div *ngFor="let error of objectKeys((formState | async).errors._email)">
          <i class="n2-ico-mask n2-ico-mask__alert"></i> {{ 'pages.authentication.login.form.errors.' + error | translate }}
        </div>
      </div>
      <div class="error-text" *ngIf="(formState | async).userDefinedProperties.serverError">
        <i class="n2-ico-mask n2-ico-mask__alert"></i> {{(formState | async).userDefinedProperties.serverError}}
      </div>
      <div class="input-label">{{ 'pages.authentication.forgot_password.form.email' | translate }}</div>
      <input type="email" name="email"
        (focus)="onFocus()"
        [ngrxFormControlState]="(formState | async).controls.email"
        [ngrxEnableFocusTracking]="true"
        [ngClass]="{'error': (formState | async).controls.email.isDirty && (formState | async).controls.email.isInvalid && (formState | async).controls.email.isUnfocused }">
      <button type="submit" class="forgot-password-bottom-button"
        [disabled]="(disabled && (formState | async).controls.email !== '') || (formState | async).isInvalid">
        {{ 'pages.authentication.forgot_password.form.submit' | translate }}
      </button>
    </form>
  </ng-template>

  <ng-template [ngIf]="isSent">
    <img src="assets/imgs/funz-mail-send.svg"/>
    <div class="forgot-password-link-text">{{'pages.authentication.forgot_password.link_sent' | translate}}</div>
    <div class="forgot-password-link-choose">{{'pages.authentication.forgot_password.choose_new' | translate}}</div>
    <div class="forgot-password-not-received" (click)="goToRecover()">{{'pages.authentication.forgot_password.not_received' | translate}}</div>
    <button ion-button (click)="goToLogin()" class="forgot-password-bottom-button">{{'pages.authentication.forgot_password.back' | translate}}</button>
  </ng-template>
</div>
