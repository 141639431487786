<ion-header>
  <div class="modal-header">
    <div class="modal-back-button" (click)="goBack()">
      <i class="n2-ico n2-ico__back-btn-black"></i>
    </div>
    <div class="modal-header-title">{{ (username$ | async) || username }}</div>
  </div>
  <!-- <app-header [backBtn]="true" [title]="(username$ | async) || username"></app-header> -->
</ion-header>

<ion-content>
  <sticky-loader [loading]="isLoading" [whiteBg]="true"></sticky-loader>
  <div class="content-wrapper">
    <ion-grid id="scroll" *ngIf="conversation$ | async as conversation">
      <ion-infinite-scroll (ionInfinite)="doInfinite($event)" position="top"
        [disabled]="(currentPage > totalPages) && totalPages">
        <ion-infinite-scroll-content color="primary" loadingSpinner="dots">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
      <ion-row class="chat-message" *ngFor="let message of messages$ | async; let index = index" id="{{index % 25}}">
        <ion-col size="1" *ngIf="!isCurrentUser(message.author_id, conversation.other_party_id) as isCurrentUser">
          <div class="chat-avatar">
            <img [src]="conversation.other_party_avatar">
          </div>
        </ion-col>
        <ion-col size="11" [ngClass]="{'chat-of-user': isCurrentUser(message.author_id, conversation.other_party_id)}">
          <div class="arrow"></div>
          <div class="chat-text">
            {{ message.body }}
          </div>
          <div class="chat-date">
            {{ message.created_at | localizeDate:'dd.MM.y' }}
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>

<ion-footer class="chat-type" *ngIf="conversation$ | async as conversation">
  <form [formGroup]="reply" (ngSubmit)="handleReply(conversation)">
    <ion-grid class="ion-no-padding">
      <ion-row>
        <ion-col size="9">
          <ion-textarea #messageArea
          rows="3"
          [placeholder]="'pages.activities.inbox.chat.type' | translate" 
          formControlName="replyText"
          [ngClass]="{ 'row-2': textareaRows === 1, 'row-3': textareaRows === 2, 'row-4': textareaRows >= 3}">
        </ion-textarea>
        </ion-col>
        <ion-col size="3" class="send-section ion-align-self-end">
          <button class="send-btn" type="submit">
            {{ 'pages.activities.inbox.chat.send' | translate }}
          </button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
  <div class="chat-term-text-container">
    {{'pages.activities.inbox.chat.term.part1' | translate}}
    <a href="javascript:void(0);" (click)="openTermOfService()">{{'pages.activities.inbox.chat.term.part2' | translate}}</a>
    {{'pages.activities.inbox.chat.term.part3' | translate}} 
    <a href="mailto:support@funzing.com">support@funzing.com</a>
  </div>
</ion-footer>
