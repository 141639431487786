<div class="order-cover" [ngStyle]="{ 'background-image': getBgImage(order.cover_url) }">
  <button appGetDir appShare
    [shareUrl]="order.share_url"
    [shareUrls]="order.share_urls"
    [shareText]="'pages.order-details.share_url' | translate"
    [shareSource]="'orders'"
    (click)="trackClick()"
    class="share-btn order-cover-share">
    <i class="n2-ico n2-ico__share-w"></i>
  </button>
  <div class="order-cover-overlay" (click)="showOrder()"></div>
  <div class="order-title" (click)="showOrder()">
    <span>{{ order.title }}</span>
    <div class="order-badge">
      <span
        class="order-badge-text"
        [ngClass]="{
          'order-highlight': isOrderPink(order),
          'order-available': isOrderAvailable(order)
        }">
        {{ 'order.status.' + order.status | translate }}
      </span>
    </div>
  </div>
</div>