<ion-header>
  <div class="search-header">
      <div class="modal-back-button" (click)="cancel()">
        <i class="n2-ico n2-ico__back-btn-black"></i>
      </div>
    <div class="search-header-title">{{ 'menu.contact_us' | translate }}</div>
  </div>
</ion-header>
<ion-content>
  <div class="contact-us-modal-container">
    <div class="contact-us-container contact-container">
      <h3 class="contact-us-header">{{ 'component.contact-modal.contact-header' | translate }}</h3>
      <span>
        <span (click)="sendEmail()">
          <i class="n2-ico n2-ico__email-sign"></i>{{ supportEmail$ | async }}
        </span>
      </span>
      <span *ngIf="isHe" class="whatsapp-btn-wrapper">
        <span *ngIf="cordova" (click)="whatsappMessage()" class="whatsapp-btn">
          {{ 'menu.contact_us.whatsapp' | translate }} <i class="n2-ico n2-ico__i-share-whatsapp"></i>
        </span>
        <a *ngIf="!cordova" href="https://api.whatsapp.com/send?phone=972559686141" target="_blank" class="whatsapp-btn">
          {{ 'menu.contact_us.whatsapp' | translate }} <i class="n2-ico n2-ico__i-share-whatsapp"></i>
        </a>
      </span>
    </div>
    
    <div class="contact-us-divider"></div>
    <div class="contact-us-container">
      <h3 class="contact-us-header">{{ 'component.contact-modal.company-header' | translate }}</h3>
      <span><a (click)="cordova ? openUrl('about-mobile') : openUrl('about')">{{ 'component.contact-modal.about_us' | translate }}</a></span>
      <span><a (click)="openUrl('faq')">{{ 'component.contact-modal.faq' | translate }}</a></span>
      <span><a (click)="openUrl('affiliates')">{{ 'component.contact-modal.affiliate' | translate }}</a></span>
      <span><a (click)="openUrl('cancellation-policy')">{{ 'component.contact-modal.cancellation' | translate }}</a></span>
      <span><a (click)="openUrl('terms-of-service')">{{ 'component.contact-modal.terms' | translate }}</a></span>
      <span><a (click)="openUrl('privacy-policy')">{{ 'component.contact-modal.privacy' | translate }}</a></span>
    </div>
    <div class="version-container" *ngIf="platform.is('mobile')">
        <section class="version-text">
          {{ 'pages.settings.version' | translate }} {{ appVersionNumber }}
        </section>

      <!-- dev only -->
        <h4 class="list-header" *ngIf="isDevMode">
          <ion-badge>
            env: {{ env.mode }}
          </ion-badge>
          <ion-badge>
            platform: {{ !cordova ? 'web' : platform.is('android') ? 'Android' : platform.is('ios') ? 'iOS' : 'web' }}
          </ion-badge>
          <span *ngIf="cordova && isDevMode && platform.is( 'mobile') ">
            <ion-badge>
              os: {{ device.version }}
            </ion-badge>
            <ion-badge>
              code-ver: {{ codeVersionNumber }}
            </ion-badge>
          </span>
        </h4>
      </div>
  </div>
</ion-content>
