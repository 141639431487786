<span>{{event.date}}</span>

{{event.start_time}}

<ion-label class="sold-out" *ngIf="event.sold_out || (event.tickets_left == 0)">
  <div class="chip-container">
    <ion-chip color="primary" float-left>
      <ion-label>{{ 'components.event-box.sold_out' | translate }}
      </ion-label>
    </ion-chip>
  </div>
</ion-label>
