<ion-app>
  <div class="pwa-header-menu" *ngIf="!cordova && (showMenu$ | async)">
    <div class="pwa-header-menu__bg" (click)="closeMenu()"></div>
    <div class="pwa-header-menu__inner">
      <funz-menu (closeMenu)="closeMenu()"></funz-menu>
    </div>
  </div>
  <ion-router-outlet></ion-router-outlet>
  <cookie-consent-toast></cookie-consent-toast>
  <div class="promo-banner" *ngIf="showPromoBanner">
    <div class="promo-banner__close" (click)="closeBanner()">&#x2715;</div>
    <div class="promo-banner__inner" (click)="goGiftcard()"></div>
  </div>
</ion-app>
