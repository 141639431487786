<div class="loyalty-card-section">
  <ion-row justify-content-center align-items-center>
    <ion-col col-2>
      <div class="loyalty-card-wrapper">
        <div class="loyalty-card" [ngClass]="showPoints == 999 ? 'big' : 'small'">
          <div class="loyalty-point-wrapper">
            <loyalty-point [point]="showPoints" [totalLoyaltyPoints]="showTotalLoyaltyPoints"></loyalty-point>
          </div>
        </div>
      </div>
    </ion-col>

    <ion-col col-9>
      <div class="loyalty-card-message" [innerHTML]="loyaltyCardMessage$ | async">
      </div>
    </ion-col>
  </ion-row>
</div>