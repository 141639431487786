<div class="category-title-box" *ngIf="category.category">
  <div class="cat-title" (click)="navigate(category.category)">{{ category.category.mobile_display_name }}</div>
  <span class="view-all" (click)="navigate(category.category)" *ngIf="!hideSeeAll">
    {{ 'pages.discover.funz_scroll.see_all' | translate }}
  </span>
</div>
<div class="funz-scroll" (scroll)="scrollHandler($event)">
  <div class="scroll-area">
    <funz-box
      *ngFor="let funz of category.funzes; index as i; trackBy: trackByFunzIdFn"
      [funz]="funz"
      [showHeart]="showHeart"
      [category]="category.category">
    </funz-box>
    <div class="view-all-box" *ngIf="!hideSeeAll && category.category" (click)="navigate(category.category)">
      <div>
        <div><i class="n2-ico-mask n2-ico-mask__arrow-forward-long"></i></div>
        {{ 'pages.discover.funz_scroll.see_all_related' | translate }}
      </div>
    </div>
  </div>
</div>
