<div class="app-header" *ngIf="cordova" [class.is-android]="isAndroid">
  <div class="app-header__back-btn">
    <i class="n2-ico n2-ico__back-btn-black" *ngIf="backBtn" (click)="goBack()"></i>
  </div>
  <div class="app-header__title">{{ title }}</div>
  <div class="app-header__close-btn"></div>
</div>
<div class="pwa-header" *ngIf="!cordova" [ngClass]="{ 'is-home': homePage }">
  <div><i class="n2-ico-mask n2-ico-mask__menu-bars" (click)="openMenu()"></i></div>
  <div *ngIf="homePage">
    <i class="n2-ico-mask n2-ico-mask__funzing-logo funzing-logo" (click)="openDiscoverPage()"></i>
  </div>
  <div *ngIf="!homePage" (click)="openDiscoverPage()">
    <img class="pwa-header__logo" src="assets/imgs/new_funz_logo.svg" />
  </div>
  <div><i class="n2-ico-mask n2-ico-mask__search-icon" (click)="goToSearchPage()"></i></div>
</div>