<div class="ticket-order-notification">
  <div class="checkmark-container">
    <i class="n2-ico-mask n2-ico-mask__checkmark"></i>
  </div>
  <div class="notification-msg">
    <div>{{ message }}</div>
    <div class="close-button-container" (click)="dismiss()">
      <i class="n2-ico-mask n2-ico-mask__close"></i>
    </div>
  </div>
</div>
