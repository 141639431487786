<ion-item class="subscription-item" no-lines>
  <img item-start class="wiser-image" src="assets/imgs/wiser-popup.png">
  <ion-label item-content>
    <h2 class="item-title" text-capitalize ion-text color="primary">
      {{ 'components.subscription.subscription-banner.title' | translate }}
    </h2>
    <p class="item-content" ion-text color="dark">
      {{ 'components.subscription.subscription-banner.content' | translate }}
    </p>
    <button color="primary" ion-button icon-button clear class="more-info-button" no-margin no-padding (click)="onMoreInfoViewOpen.emit()">
      ?
    </button>
  </ion-label>
</ion-item>