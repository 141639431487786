<ion-item>
  <div class="subscription-info-container" item-end>
    <h1 class="subscription-info-header">{{'components.subscription-info.header' | translate}} </h1>
    <ul class="subscription-info-list">
      <li>
        <span>{{'components.subscription-info.plan' | translate : { 'plan_name': subscription.plan_name } }}
          <a href="javascript:void(0)"
             (click)="goToLecturePage()">{{'components.subscription-info.here' | translate}}</a>.
        </span>
      </li>
      <li>
        <span>{{'components.subscription-info.subscription-start' | translate}}</span>
      </li>
      <li>
        <span>{{'components.subscription-info.first-month' | translate}}</span>
      </li>
      <li>
        <span>{{'components.subscription-info.advanced-booking' | translate}}</span>
      </li>
      <li>
        <span>{{'components.subscription-info.next-subscription' | translate}} {{subscription.next_billing_date | date : 'dd.LL.yyyy'}}</span>
      </li>
      <li>
        <span>{{'components.subscription-info.cancel.part1' | translate}}
          <a href="javascript:void(0)"
             (click)="goToSubscriptionSettingPage()">{{'components.subscription-info.cancel.part2' | translate}}</a>
        , {{'components.subscription-info.cancel.part3' | translate}}
        </span>
      </li>
    </ul>
    <div class="subscription-info-text-block">{{'components.subscription-info.refund' | translate}}
      <a href="javascript:void(0)" (click)="sendEmail()">{{supportEmail}}</a>
    </div>

    <div class="subscription-info-text-block">{{'components.subscription-info.agreement' | translate : { 'plan_name': subscription.plan_name } }}
      <a href="javascript:void(0)" (click)="openTermOfService()">{{'components.subscription-info.terms' | translate}}</a>
    </div>
  </div>
</ion-item>
