<div class="loyalty-point" [ngClass]="classesArray">
  <span class="num" *ngIf="point < 6">
    {{point}}
  </span>

  <i class="fa fa-star"></i>
  <span *ngIf="point == 6 || point == 999">
    Free
  </span>
</div>
