<div class="loyalty-card-section" *ngIf="slanted == false">
  <ion-row align-items-center>
    <ion-col col class="loyalty-card-stars">
      <div class="loyalty-card-wrapper">
        <div class="loyalty-card">
          <div class="loyalty-point-wrapper" *ngFor="let point of [1,2,3,4,5,6]">
            <loyalty-point [point]="point" [totalLoyaltyPoints]="totalLoyaltyPoints"></loyalty-point>
          </div>
        </div>
      </div>
    </ion-col>
    <ion-col col>
      <div class="loyalty-card-message" [innerHTML]="loyaltyCardMessage$ | async"></div>
    </ion-col>
  </ion-row>
</div>

<div class="loyalty-card-wrapper" *ngIf="slanted == true">
  <div class="loyalty-card small" [ngClass]="{'slanted': slanted == true }">
    <div class="loyalty-point-wrapper" *ngFor="let point of [1,2,3,4,5,6]">
      <loyalty-point [point]="point" [totalLoyaltyPoints]="totalLoyaltyPoints"></loyalty-point>
    </div>
  </div>
</div>