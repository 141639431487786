<div class="require-auth-modal-container">
  <img class="auth-icon" src="../../assets/imgs/funz-login.svg"/>
  <div class="require-auth-modal-header">
    {{ 'component.require-auth.need-account' | translate }}
  </div>
  <div class="require-auth-modal-subheader">
    {{ 'component.require-auth.please-log' | translate }}
  </div>
  <div class="require-auth-modal-button-container">
    <button class="require-auth-modal-button-signup" (click)="dismiss('Registration')">
      {{ 'component.require-auth.signup' | translate }}
    </button>
    <button class="require-auth-modal-button-login" (click)="dismiss('Login')">
      {{'component.require-auth.login' | translate}}
    </button>
  </div>
  <button class="require-auth-modal-cancel-button" (click)="dismiss()">
    {{ 'component.require-auth.cancel' | translate }}
  </button>
</div>
