<div class="form-header">
  <ng-template [ngIf]="!(fromMenu$ | async)">
    <h2>{{ 'pages.authentication.register.quick_signup' | translate }}</h2>
    <div>{{ 'pages.authentication.register.please_register' | translate }}</div>
  </ng-template>

  <ng-template [ngIf]="fromMenu$ | async">
    <h2>{{ 'pages.authentication.register.welcome_funzing' | translate }}</h2>
    <div>{{ 'pages.authentication.register.quick_join' | translate }}</div>
  </ng-template>
</div>

<div class="form-subheader">
  {{ 'pages.authentication.register.signin.text' | translate }}
  <span class="subheader-link" (click)="goToEmailSignIn()">
    {{ 'pages.authentication.register.signin.link' | translate }}
  </span>
</div>

<form [ngrxFormState]="(formState | async)" (submit)="onSubmit()">
  <div class="main-error-text" *ngIf="showMainError">
    <i class="n2-ico-mask n2-ico-mask__alert"></i> {{ 'pages.authentication.register.form.errors' | translate}}
  </div>
  <div class="input-label">{{ 'pages.authentication.register.form.name' | translate }}</div>
  <input (focus)="onFocus($event)"
    (blur)="onBlur()"
    class="first-input"
    type="text"
    name="name"
    [ngrxFormControlState]="(formState | async).controls.name"
    [ngrxEnableFocusTracking]="true"
    [ngClass]="{'error': showErrors && (formState | async).controls.name.isInvalid && (formState | async).controls.name.isUnfocused}">
  <div #error class="error-text" *ngIf="showErrors && (formState | async).controls.name.isUnfocused && (formState | async).errors._name">
    <div *ngFor="let error of objectKeys((formState | async).errors._name)">
      {{ 'pages.authentication.register.form.errors.' + error | translate }}
    </div>
  </div>
  <div #error class="error-text" *ngIf="(formState | async).userDefinedProperties.name && (formState | async).userDefinedProperties.name.error">
    {{(formState | async).userDefinedProperties.name.error}}
  </div>

  <div class="input-label">{{ 'pages.authentication.register.form.email' | translate }}</div>
  <input (focus)="onFocus($event)"
    (blur)="onBlur()"
    type="email"
    name="givenEmail"
    [ngrxFormControlState]="(formState | async).controls.givenEmail"
    [ngrxEnableFocusTracking]="true"
    [ngClass]="{ 'error': showErrors &&
    ((formState | async).errors._givenEmail && (formState | async).controls.givenEmail.isUnfocused) || (
    (formState | async).userDefinedProperties.givenEmail && (formState | async).userDefinedProperties.givenEmail.error) }">
  <div #error class="error-text" *ngIf="showErrors && (formState | async).controls.givenEmail.isUnfocused && (formState | async).errors._givenEmail">
    <div *ngFor="let error of objectKeys((formState | async).errors._givenEmail)">
      {{ 'pages.authentication.register.form.errors.' + error | translate }}
    </div>
  </div>
  <div #error class="error-text" *ngIf="(formState | async).userDefinedProperties.givenEmail && (formState | async).userDefinedProperties.givenEmail.error">
    {{(formState | async).userDefinedProperties.givenEmail.error}}
  </div>

  <div class="input-label">{{ 'pages.authentication.register.form.password' | translate }}</div>
  <div class="password-input-container">
    <input (focus)="onFocus($event)"
      (blur)="onBlur()"
      [type]="passwordFieldType"
      name="firstPwd"
      autocomplete="new-password"
      [ngrxFormControlState]="(formState | async).controls.password.controls.firstPwd"
      [ngrxEnableFocusTracking]="true"
      [ngClass]="{'error': showErrors && (formState | async).controls.password.controls.firstPwd.isInvalid && (formState | async).controls.password.controls.firstPwd.isUnfocused }">
    <span (click)="changePasswordFieldType()">
      {{(passwordFieldType === 'password' ? 'pages.authentication.register.form.show_password' : 'pages.authentication.register.form.hide_password') | translate}}
    </span>
  </div>

  <div #error class="error-text" *ngIf="showErrors && (formState | async).controls.password.controls.firstPwd.isUnfocused && (formState | async).errors._password && (formState | async).errors._password._firstPwd">
    <div *ngFor="let error of objectKeys((formState | async).errors._password._firstPwd)">
      {{ 'pages.authentication.register.form.errors.' + error | translate }}
    </div>
  </div>
  <div #error class="error-text" *ngIf="(formState | async).userDefinedProperties.firstPwd && (formState | async).userDefinedProperties.firstPwd.error">
    {{(formState | async).userDefinedProperties.firstPwd.error}}
  </div>

  <div class="input-label">{{ 'pages.authentication.register.form.confirm_password' | translate }}</div>
  <input (focus)="onFocus($event)"
    (blur)="onBlur()"
    type="password"
    name="confirmPassword"
    [ngrxFormControlState]="(formState | async).controls.password.controls.confirmPassword"
    [ngrxEnableFocusTracking]="true"
    [ngClass]="{'error': showErrors && (formState | async).controls.password.controls.confirmPassword.isUnfocused && (formState | async).errors._password && (formState | async).errors._password.match}">
  <div #error class="error-text"
    *ngIf="showErrors && (formState | async).controls.password.controls.confirmPassword.isUnfocused
    && (formState | async).errors._password
    && !((formState | async).controls.password.controls.confirmPassword.value)">
    {{ 'pages.authentication.register.form.errors.required' | translate }}
  </div>
  <div #error class="error-text" *ngIf="showErrors && (formState | async).controls.password.controls.confirmPassword.isUnfocused
    && (formState | async).errors._password && (formState | async).errors._password._confirmPassword
    &&(formState | async).errors._password._confirmPassword.equalTo
    && (formState | async).controls.password.controls.confirmPassword.value">
    {{ 'pages.authentication.register.form.errors.password_match' | translate }}
  </div>

  <div *ngIf="!isIL" class="send-marketing-promotions">
    {{ 'pages.authentication.landing.signin_marketing_promotions' | translate }}
  </div>
  <marketing-opt *ngIf="isIL"></marketing-opt>
  <div *ngIf="isIL" class="tnc-opt">
    <i class="n2-ico-mask n2-ico-mask__checkbox-outline" *ngIf="tncChecked" (click)="toggleTncChecked()"></i>
    <i class="n2-ico-mask n2-ico-mask__square-outline" *ngIf="!tncChecked" (click)="toggleTncChecked()"></i>
    <div (click)="toggleTncChecked()" [innerHTML]="tncText"></div>
  </div>
  <div #error class="tnc-error-text" *ngIf="isIL && tncShowErrors">
    {{ 'pages.authentication.register.form.errors.required' | translate }}
  </div>
  <button class="submit-btn" type="submit" [disabled]="isDisableSubmit$ | async">
    {{ 'pages.authentication.register.form.submit' | translate }}
  </button>
</form>
<marketing-opt *ngIf="!isIL"></marketing-opt>
