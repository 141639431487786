<ion-header>
  <div class="search-header">
    <div class="modal-back-button" (click)="close()">
      <i class="n2-ico n2-ico__back-btn-black"></i>
    </div>
    <div class="search-header-title">{{ title }}</div>
  </div>
</ion-header>
<ion-content>
  <div class="text-based-modal-container" [innerHTML]="textContent"></div>
</ion-content>
<ion-footer>
</ion-footer>
