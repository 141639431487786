<ng-container *ngIf="menuData.loaded">
  <i *ngIf="!cordova" class="close-menu-btn n2-ico-mask n2-ico-mask__close" (click)="closeMenu.emit()"></i>
  <div class="menu-base-header-container" *ngIf="!menuData.userItems.signedIn">
    <div class="funzing-logo">
      <img src="assets/imgs/new_funz_logo.svg" />
    </div>
    <div class="menu-signin-buttons">
      <div class="menu-auth-buttons menu-signup-button"
        (click)="openMenuPage(menuData.authMenuItems.Registration)">
        {{ menuData.authMenuItems.Registration.title }}
      </div>
      <div class="menu-auth-buttons menu-login-button"
        (click)="openMenuPage(menuData.authMenuItems.Login)">
        {{ menuData.authMenuItems.Login.title }}
      </div>
    </div>
  </div>

  <div class="menu-signin-header-container" *ngIf="menuData.userItems.signedIn">
    <img class="user-image" 
    [src]="!menuData.userItems.userAvatar.includes('no-user') ? menuData.userItems.userAvatar: 'assets/imgs/no-user-mobile.png'"
      width="90px" height="90px">
    <div class="user-name">
      {{ menuData.userItems.userName }}
    </div>
    <div class="user-email">
      {{ menuData.userItems.email }}
    </div>
    <div menuClose class="menu-profile-button" (click)="openMenuPage(menuData.userItems.profilePage)">
      {{ 'menu.view_profile' | translate }}
    </div>
  </div>

  <ion-list class="pwa-menu-items" [class.is-pwa]="!cordova">
    <ng-container *ngFor="let p of menuData.navigationLinks">
      <ion-item class="menu-items" lines="none"
        [class.item-active]="!cordova && currentUrlParts.includes(p.pageName)"
        *ngIf="cordova 
          ? (!p.requireLogin || menuData.userItems.signedIn) && p.icon !== 'funz-home' && p.icon !== 'funz-order' && p.icon !== 'funz-heart' 
          : (!p.requireLogin || menuData.userItems.signedIn)"
        (click)="openMenuPage(p)">
        <div class="menu-item-text" [ngClass]="{ 'is-contact' : p.icon === 'funz-contact', 'pwa': !cordova  }">
          <i class="n2-ico-mask n2-ico-mask__menu-{{ p.icon }}"></i>
          <span>{{ p.title }}</span>
          <i *ngIf="p.icon === 'funz-contact'" class="n2-ico-mask n2-ico-mask__menu-funz-contact-arr"></i>
        </div>
      </ion-item>
    </ng-container>
    <ion-item *ngIf="menuData.userItems.signedIn" lines="none"
        (click)="openMenuPage(menuData.logoutMenuItem)"
        class="menu-items logout-item">
      <div class="menu-item-text" [ngClass]="{ 'pwa': !cordova }">
        <i class="n2-ico-mask n2-ico-mask__menu-{{ menuData.logoutMenuItem.icon }}"></i>
        <span>{{ menuData.logoutMenuItem.title }}</span>
      </div>
    </ion-item>
  </ion-list>
</ng-container>