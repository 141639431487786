<ion-grid>
  <ion-row>
    <ion-col col-12>
      <section class="subscription-link-text">
        {{'component.order.order-details.subscription.coupon' | translate}}: {{subscription.coupon_code}}
        <br>
        {{'component.order.order-details.subscription.next-date' | translate}}: {{subscription.next_billing_date | date : 'dd.LL.yyyy'}}
        <br>
        <a href="javascript:void(0)" (click)="onLinkClicked()">
          {{'component.order.order-details.subscription.more-info' | translate}}
        </a>
      </section>
    </ion-col>
  </ion-row>
</ion-grid>
