<ion-header>
  <div class="modal-header">
    <div class="modal-header-title">{{ headerTitle }}</div>
    <div class="modal-close-button" (click)="close()">
      <i class="back-x n2-ico n2-ico__search_x"></i>
    </div>
  </div>
</ion-header>
<ion-content>
  <div class="message-sent-img"></div>
  <div class="message-sent-thank-block">{{'pages.contact-sent.thank' | translate}}</div>
  <div class="message-sent-host-info">{{'pages.contact-sent.host-info' | translate}}</div>
</ion-content>
<ion-footer>
  <div class="footer-wrapper">
    <button class="btn-view-msg" (click)="goToChat()">{{ 'pages.contact-sent.view-chat' | translate }}</button>
  </div>
</ion-footer>
