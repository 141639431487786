<div class="general-fb-onboard-container">
  <div class="welcome-text">
    {{ 'pages.authentication.register.welcome_funzing' | translate }}
  </div>
  <div class="please-review-text">
    {{ 'pages.authentication.onboarding.please_review' | translate }}
  </div>
  <div class="name-title">
    {{ 'pages.authentication.register.form.name' | translate }}
  </div>
  <div class="name-input">
    {{ fbOnboardData.name }}
  </div>
  <div class="email-title">
    {{ 'pages.authentication.forgot_password.form.email' | translate }}
  </div>
  <div class="email-input">
    {{ fbOnboardData.email }}
  </div>
  <div class="personal-info-message">
    {{ 'pages.authentication.onboarding.info_came_from_fb' | translate }}
  </div>
  <div class="line"></div>
  <div class="marketing-notice">
    {{ 'pages.authentication.landing.signin_marketing_promotions' | translate }}
  </div>
  <button class="complete-button" (click)="closeModal(true)">
    {{ 'pages.authentication.onboarding.finish_signing_up' | translate }}
  </button>
  <div class="marketing-opt">
    <div class="checkbox-holder">
      <i class="n2-ico-mask n2-ico-mask__checkbox-outline"  *ngIf="fbOnboardData.notSubscribedToMarketingEmails" (click)="toggleMarketingOptIn()"></i>
      <i class="n2-ico-mask n2-ico-mask__square-outline" *ngIf="!fbOnboardData.notSubscribedToMarketingEmails" (click)="toggleMarketingOptIn()"></i>
    </div>
    <div class="marketing-text" (click)="toggleMarketingOptIn()">
      {{ 'pages.authentication.marketing_opt_in' | translate }}
    </div>
  </div>
</div>
