<div class="ticket-notification" 
  [ngClass]="{ 'with-exclamation' : showExclamation }"
  [ngStyle]="{ 'color': backgroundColor }" >
  <div class="checkmark-container"
    *ngIf="showCheckmark || showExclamation"
    [ngStyle]="{ 'background-color': backgroundColor }">
    <!-- <ion-icon [name]="showCheckmark ? 'checkmark' : 'alert'"></ion-icon> -->
  </div>
  <div class="notification-msg" [ngClass]="{ 'with-checkmark' : showCheckmark || showExclamation }">
    <div *ngIf="showExclamation">
      <b [ngStyle]="{ 'color': backgroundColor }">{{ 'components.ticket-notification.couldnt_complete' | translate }}</b>
    </div>
    <div>{{ message }}</div>
  </div>
</div>
