<div class="gift-card-modal-container">
  <div class="modal-close" (click)="close()">
    <i class="n2-ico n2-ico__search_x"></i>
  </div>
  <img src="/assets/imgs/funz-gift-card.svg"/>
  <div class="gift-card-modal-header">{{ 'components.gift-card-modal.header' | translate }}</div>
  <div class="gift-card-modal-subheader">
    {{ 'components.gift-card-modal.subheader' | translate }}
  </div>
  <button class="gift-card-buy-btn" (click)="goToGiftCard()">
    {{ 'components.gift-card-modal.button' | translate }}
  </button>
</div>
