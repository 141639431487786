export const ENV = {
  mode: 'Prod',
  default_base_domain: 'https://api-uk.funzing.com/api/v2',
  default_root_domain: 'https://uk.funzing.com',
  default_root_api_domain: 'https://api-uk.funzing.com',
  codePush_ios_deployment_key: '6JP6N5BwDhIkx5q05T5Wah5SsxfsSkz7pRR6G ',
  codePush_android_deployment_key: 'iJ2ooy25fMmP3KK-_axE6YpHyfoyF7YXLXsW-',
  hockeyapp_android_app_id: 'b0d37fcf1744409683fbf1a39c07045b',
  hockeyapp_ios_app_id: 'bbbf8f807eb844528bfeaa2e920d8f55',
  one_signal_app_id: '2a3ec146-ff39-4c34-8f5a-c4bd7a85da91',
  one_signal_google_project_number: '1018497937930',
  cloudinary_base_url: 'https://res.cloudinary.com/dnn7h5tec/image/upload/',
  cloudinary_image_set: 'prod',
  mapbox_token:
    'pk.eyJ1IjoicGVkcm9ndXNtYW4iLCJhIjoiY2poeWx4c2c4MGpqczNxbnZ6ajBpN29zdCJ9.K2vQrB0pZbbA0QDH2_RHNA',
  seatsio_public_key: 'f01fd322-1c92-4da8-a1f4-9d7d0cb23b63',
  facebook_app_id: '293397947481449',
  facebook_app_name: 'funzing-production',
  google_web_application_client_id: '355852510617-gjiblrd0i9d7f9ctvniaboigv2l0dbqf.apps.googleusercontent.com',
  core_domain: 'funzing.com',
  cached_json_external_url: '/assets/api',
  cooladata_app_key: 'dv5xhuz6zbreeh7y6p9oxwncyva6apbr',
  onesignal: {
    il: {
      chrome_id: '5f0eb22a-7610-42f6-9282-4a182f838967',
      safari_id: 'web.onesignal.auto.54d60a3d-c0d7-4ae1-b490-c29a9cfa7b70'
    },
    uk: {
      chrome_id: '515c80a3-1142-4164-a574-eedcf13a7b88',
      safari_id: 'web.onesignal.auto.5e2915a8-1095-4900-b2af-7b25bf2970dd'
    }
  },
  production: true
};
