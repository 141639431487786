<ion-header>
  <div class="modal-header">
    <div class="modal-back-button" (click)="skipLogin()">
      <i class="n2-ico n2-ico__back-btn-black"></i>
    </div>
    <div class="modal-header-title">
      <ng-container *ngIf="authType === 'Registration'">{{ 'pages.authentication.landing.create_account' | translate }}</ng-container>
      <ng-container *ngIf="authType === 'Login'">{{ 'pages.authentication.login.header' | translate }}</ng-container>
      <ng-container *ngIf="authType === 'SignUp'">{{ 'pages.authentication.landing.create_account' | translate }}</ng-container>
      <ng-container *ngIf="authType === 'password'">{{ 'pages.authentication.landing.password_recovery' | translate }}</ng-container>
      <ng-container *ngIf="authType === 'facebook-onboarding'">{{ 'pages.authentication.landing.facebook' | translate }}</ng-container>
    </div>
  </div>
</ion-header>
<ion-content>
  <auth-forms
  [displayedComponent]="authType" 
  (close)="skipLogin()" 
  (login)="handleLogin()" 
  (register)="handleRegister()"
  (resetPassword)="handleResetPassword()">
</auth-forms>
</ion-content>
