<div class="event-availability">
  <div class="event-availability__icon">
    <img src="../../assets/imgs/funz-warn.svg"/>
  </div>
  <div class="event-availability__title">
    {{ 'components.order.event-availability.title' | translate }}
  </div>
  <div class="event-availability__subtitle">
    {{ 'components.order.event-availability.subtitle' | translate }}
  </div>
  <div class="event-availability__continue" (click)="dismiss(true)">
    {{ 'components.order.event-availability.continue' | translate }}
  </div>
  <div class="event-availability__cancel" (click)="dismiss()">
    {{ 'pages.settings.confirm.cancel' | translate }}
  </div>
</div>