<section class="tabs-ios">
  <div class="tabbar show-tabbar safe-area" role="tablist">
    <!-- TODO Remove this components -->
    <a *ngFor="let tab of tabs; index as i" class="funz-tab-button has-title has-icon disable-hover" href="#" [attr.aria-controls]="'tabpanel-t0-' + i"
      [attr.aria-selected]="isActive(tab.page)" (click)="onTabSelect($event, tab.page)">
      <!-- <ion-icon [ngClass]="tab.icon" class="tab-button-icon icon icon-ios" role="img" [attr.aria-label]="'custom ' + tab.page"></ion-icon> -->
      <ng-container *ngIf="tab.useOverlay">
        <div class="selected-overlay" *ngIf="unreadNotificationsCounter$ | async"></div>
      </ng-container>
      <span class="tab-button-text">{{ tab.text | translate }} </span>
    </a>

  </div>
</section>