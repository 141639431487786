<div class="order-payment-wait-modal-container">
  <i class="n2-ico-mask n2-ico-mask__close" (click)="close()"></i>
  <div class="order-payment-wait-modal-header">
    {{ 'component.order-payment-wait-modal.order_was_placed' | translate }}
  </div>
  <div class="order-payment-wait-description">
    {{ 'component.order-payment-wait-modal.thank_you_for_booking' | translate }} {{ funzTitle }}. 
    {{ 'component.order-payment-wait-modal.you_have_to_wait' | translate }}
  </div>
  <button class="order-payment-wait-modal-cancel-button" (click)="close(true)">
    {{ 'component.order-payment-wait-modal.view_order_details' | translate }}
  </button>
</div>
