<section *ngIf="hasFeedback && !editMode" class="feedback-comments">
  <ion-grid>
    <ion-row>
      <ion-col size="1">
        <div class="feedback-list-status" [ngClass]="{'thumbs-down': likeStatus === 'thumbs-down', 'thumbs-up': likeStatus === 'thumbs-up'}">
          <i class="n2-ico" [ngClass]="{'n2-ico__thumbs-down': likeStatus === 'thumbs-down', 'n2-ico__thumbs-up': likeStatus === 'thumbs-up'}"></i>
        </div>
      </ion-col>
      <ion-col size="11">
        <div class="feedback-comment" [ngClass]="{ 'cut' : !full }">"{{ order.feedback.comment }}"</div>
        <div class="edit-feedback" (click)="editComment()" *ngIf="order.can_edit_feedback"> {{ 'components.order.order_feedback.edit' | translate }}</div>
      </ion-col>
    </ion-row>
  </ion-grid>
</section>
<section *ngIf="hasFeedback && !editMode && showReply" class="feedback-comments">
  <ion-grid>
    <ion-row *ngIf="hasReply" class="seller-feedback">
      <ion-col>
        <ion-row>
          <ion-col size="3">
            <div class="feedback-list-status" [ngStyle]="{ 'background-image': getOwnerImage(order.owner.avatar) }">
            </div>
          </ion-col>
          <ion-col size="9" class="seller-feedback-title">
            {{ order.owner.full_name }}
            <div class="seller-feedback-date">{{ order.feedback.seller_replied_at | localizeDate:'d MMM yy' }}</div>
          </ion-col>
        </ion-row>
        <div class="feedback-comment seller-comment">
          "{{ order.feedback.seller_reply }}"
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</section>
<form [formGroup]="commentForm">
  <ion-grid class="comment-form card-bg">
    <ion-row>
      <ion-col class="comments-area">
        <ion-textarea 
          placeholder="{{ 'components.order.order_feedback.edit_comment' | translate }}" 
          formControlName="commentText"
          rows="4">
        </ion-textarea>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="comment-buttons">
        <button class="btn-dont-like" [disabled]="!commentForm.valid" (click)="commentOrder(false)">
          {{ 'components.order.order_feedback.dont_like' | translate }}
        </button>
        <button class="btn-like" [disabled]="!commentForm.valid" (click)="commentOrder(true)">
          {{ 'components.order.order_feedback.like' | translate }}
        </button>
      </ion-col>
    </ion-row>
  </ion-grid>
</form>
