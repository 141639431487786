<ion-content>
  <div class="subscription-info-container">
    <div class="subscription-info-close" (click)="goBack()">
        <i class="n2-ico n2-ico__search_x"></i>
    </div>
    <div class="subscription-info-img"></div>
    <div class="subscription-info-header">
      {{ 'pages.susbscription.subscription-info.title' | translate }}
    </div>
    <p class="card-subtitle" ion-text>
      {{ 'pages.susbscription.subscription-info.sub-title' | translate }}
    </p>
    <ion-card-content>
      <ion-list>
        <ion-item lines="none" class="ion-no-margin">
          <h4 slot="start">{{'1.'}}</h4>
          <p>{{ 'pages.susbscription.subscription-info.list-item-1' | translate }}</p>
        </ion-item>
        <ion-item lines="none" class="ion-no-margin">
          <h4 slot="start">{{'2.'}}</h4>
          <p>{{ 'pages.susbscription.subscription-info.list-item-2' | translate }}</p>
        </ion-item>
        <ion-item lines="none" class="ion-no-margin">
          <h4 slot="start">{{'3.'}}</h4>
          <p>{{ 'pages.susbscription.subscription-info.list-item-3' | translate }}</p>
        </ion-item>
        <ion-item lines="none" class="ion-no-margin">
          <h4 slot="start">{{'4.'}}</h4>
          <p>{{ 'pages.susbscription.subscription-info.list-item-4' | translate }}</p>
        </ion-item>
      </ion-list>
    </ion-card-content>
  </div>
</ion-content>
