<div class="bit-result">
  <div class="bit-result__inner" *ngIf="result === 'cancel'">
    <div class="bit-result__text">
      אוי! משהו לא הסתדר
    </div>
    <div class="bit-result__sub-text">
      התשלום באמצעות BIT לא התבצע. <br>אנא נסו שוב
    </div>
    <div class="bit-result__btn" (click)="close()">סגור</div>
  </div>

  <div class="bit-result__inner" *ngIf="result === 'success'">
    <b class="bit-result__sub-text">מעדכן את ההזמנה</b><br>
    <b class="bit-result__sub-text">אנא המתן...</b>
    <div class="loading-dots">
      <div class="loading-dots--dot"></div>
      <div class="loading-dots--dot"></div>
      <div class="loading-dots--dot"></div>
    </div>
  </div>
</div>
