<div class="location-rerequest">
  <div class="location-rerequest__header">{{ 'components.rerequest-location-access.header' | translate }}</div>
  <div class="location-rerequest__subheader">{{ platformSpecific | translate }}</div>
  <div class="location-rerequest__buttons">
    <div class="btn" (click)="openSettings()">{{ 'components.rerequest-location-access.go_to_settings' | translate }}</div>
    <div class="btn btn-no" (click)="closeModal()">{{ 'components.rerequest-location-access.no_thanks' | translate }}</div>
  </div>
  <div class="location-rerequest__note">
    {{ 'components.rerequest-location-access.text_describing_usage_of_users_location' | translate }}
  </div>
</div>
