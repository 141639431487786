<div class="auth-content">
  <div class="auth-forms-wrapper">
    <div class="auth-forms">
      <div [ngSwitch]="displayedComponent" class="auth-forms-comp-container">
        <user-signup *ngSwitchCase="'SignUp'"></user-signup>
        <register-email *ngSwitchCase="'Registration'" (register)="handleRegister()"></register-email>
        <login-email *ngSwitchCase="'Login'" (login)="handleLogin()"></login-email>
        <forgot-password *ngSwitchCase="'password'" (resetPassword)="handleResetPassword()"></forgot-password>
      </div>
    </div>
  </div>
</div>
