<div class="order-payment-error-modal-container">
  <img src="../../assets/imgs/funz-warn.svg"/>
  <div class="order-payment-error-modal-header">
    {{ errorMessage || 'component.order-payment-error-modal.declined' | translate }}
  </div>
  <div class="order-payment-error-modal-subheader">
    {{ errorDescription || 'component.order-payment-error-modal.subheader' | translate }}
  </div>
  <div class="order-payment-error-description">
    {{ 'component.order-payment-error-modal.description' | translate }}
  </div>
  <div class="order-payment-error-contact-block">
    {{ 'component.order-payment-error-modal.contact-description' | translate}}
    <a href="javascript:void(0)" (click)="sendEmail()">{{ 'component.order-payment-error-modal.contact-us' | translate}}</a>
  </div>
  <button class="order-payment-error-modal-cancel-button" (click)="close()">
    {{ 'component.order-payment-error-modal.close' | translate }}
  </button>
</div>
