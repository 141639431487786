<div class="order-subscription-wrapper">
  <h4 class="subscription-title">
    {{ 'components.order_subscription.title' | translate }}
  </h4>

  <div class="error" *ngIf="errorStatus.displayError">
    <i class="n2-ico-mask n2-ico-mask__alert"></i> {{ 'components.order_subscription.errors.payment_plan_select' | translate }}
  </div>

  <div *ngIf="amounts" class="subscription-container">
    <ion-radio-group>
      <div class="subscription-item">
        <ion-radio mode="md" (click)="subscriptionSelected = false; onSelect(subscriptionSelected)"></ion-radio>
        <ion-label>
          <span>{{ amounts?.subscription_data.only_tickets_str }}</span>
        </ion-label>
        <div class="sub-price">
          <b>{{ amounts.initial_price | currency:currencyCode:'symbol':'1.0-2'}}</b>
        </div>
      </div>
      <div *ngIf="amounts.subscription_data as subscription" class="subscription-item">
        <ion-radio mode="md" (click)="subscriptionSelected = true; onSelect(subscriptionSelected)"></ion-radio>
        <ion-label>
          <span class="best-value-block">{{ 'components.order_subscription.best_value' | translate}}</span>
          <span>{{ subscription.only_tickets_str }}&nbsp;+</span><br>
          <span>{{ subscription.tickets_with_sub_str }}</span><br>
          <ion-note class="card-subtitle">
            {{ subscription.sub_info_str }}. <b>{{ subscription.sub_info_str_cancel }}</b>
          </ion-note>
          <div class="subscription-more-info-button" (click)="openMoreInfoView($event)">
            {{ 'components.order_subscription.more_details' | translate }}
          </div>
        </ion-label>
        <div class="sub-price">
          <b>{{ subscription.initial_price_with_sub | currency:currencyCode:'symbol':'1.0-2' }}</b>
        </div>
      </div>
    </ion-radio-group>
  </div>
</div>
