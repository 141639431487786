<ion-card *ngFor="let ticket of order.tickets" class="ticket-card">
  <ion-card-content>
    <ion-row>
      <div class="card-subtitle">{{ 'components.order.tickets.event' | translate}}</div>
      <p><b>{{ order.title }}</b></p>
    </ion-row>
    <ion-row>
      <div class="card-subtitle">{{ 'components.order.tickets.address' | translate }}</div>
      <p>{{ order.address }}</p>
    </ion-row>
    <ion-row>
      <ion-col>
        <div class="card-subtitle">{{ 'components.order.tickets.date' | translate }}</div>
        <label class="card-label">{{ order.start_time | localizeDate:'EEEE dd.MM.yy' }}</label>
      </ion-col>
      <ion-col>
        <div class="card-subtitle">{{ 'components.order.tickets.time' | translate }}</div>
        <label class="card-label">{{ order.start_time | localizeDate:'H:mm' }}</label>
      </ion-col>
      <ion-col *ngIf="ticket.row">
        <div class="card-subtitle">{{ 'components.order.tickets.row' | translate }}</div>
        <label class="card-label">{{ ticket.row }}</label>
      </ion-col>
      <ion-col *ngIf="ticket.seat">
        <div class="card-subtitle">{{ 'components.order.tickets.seat' | translate }}</div>
        <label class="card-label">{{ ticket.seat }}</label>
      </ion-col>
    </ion-row>
    <div class="barcode-container">
      <img class="barcode-image" [src]="'data:image/jpeg;base64,'+ticket.barcode">
      <label>{{ ticket.value }}</label>
    </div>
  </ion-card-content>
</ion-card>