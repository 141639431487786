<div class="order">
  <ion-card>
    <order-cover [order]="item" (select)="showOrder()"></order-cover>
    <order-meta [order]="item" (click)="showOrder()"></order-meta>
    <div class="order-meta rate-order-title card-bg" *ngIf="showFeedback">
      {{ 'components.order.rate' | translate }}
    </div>
    <order-feedback *ngIf="showFeedback else orderDetails" [order]="item" (comment)="commentOrder($event)"></order-feedback>
    <ng-template #orderDetails>
      <order-details
        [order]="item"
        [moreLink]="true"
        [liveFunz]="item.live_funz"
        (click)="showOrder()">
      </order-details>
    </ng-template>
    <order-owner [order]="item" (callNumber)="onCallNumber($event)"></order-owner>
    <div class="order-owner order-link card-bg" (click)="showOrder()">
      {{ 'components.order.details' | translate }}
    </div>
  </ion-card>
</div>
