<div class="footer-mobile-menu">
  <div class="menu-item" [class.active]="isActiveMenu(APP_PAGES.DiscoverPage)">
    <i class="n2-ico-mask n2-ico-mask__m-discover" (click)="routeToPage(APP_PAGES.DiscoverPage)"></i><br>
    <span>{{ 'pages.discover.title' | translate }}</span>
  </div>
  <div class="menu-item" [class.active]="isActiveMenu(APP_PAGES.SearchPage)"  (click)="routeToPage(APP_PAGES.SearchPage)">
    <i class="n2-ico-mask n2-ico-mask__m-search"></i><br>
    <span>{{ 'pages.search.title' | translate }}</span>
  </div>
  <div class="menu-item" [class.active]="isActiveMenu(APP_PAGES.WishlistPage)">
    <i class="n2-ico-mask n2-ico-mask__m-wishlist" (click)="routeToPage(APP_PAGES.WishlistPage)"></i><br>
    <span>{{ 'pages.wishlist.title' | translate }}</span>
  </div>
  <div class="menu-item" [class.active]="isActiveMenu(APP_PAGES.OrdersPage)">
    <i class="n2-ico-mask n2-ico-mask__m-orders" (click)="routeToPage(APP_PAGES.OrdersPage)"></i><br>
    <span>{{ 'menu.orders' | translate }}</span>
  </div>
  <div class="menu-item" [class.active]="isActiveMenu(APP_PAGES.MenuPage)" (click)="routeToPage(APP_PAGES.MenuPage)">
    <i class="n2-ico-mask n2-ico-mask__m-more"></i><br>
    <span>{{ 'pages.funz_show.more' | translate}}</span>
  </div>
</div>
