<div class="inner">
  <ion-row justify-content-center text-center>
    <ion-col col-1>
      <img src="assets/imgs/share-with-friends-stars-left.png">
    </ion-col>
    <ion-col col-3>
      <img src="assets/imgs/share-with-friends-present.png">
    </ion-col>
    <ion-col col-1>
      <img src="assets/imgs/share-with-friends-stars-right.png">
    </ion-col>
  </ion-row>
<div class="title">{{ (platform.is('ios') ? 'component.share_with_friends.title.ios' : 'component.share_with_friends.title') | translate: {credits: referralCreditsAmount$ | async } }}</div>
<!-- <button class="label-balance" ion-button color="primary" (click)="navigateToSharePage()" no-shadow> {{ 'component.share_with_friends.button' | translate }}</button><br> -->
</div>
