<div class="event-orderable">
  <div class="event-orderable__icon">
    <img src="../../assets/imgs/funz-warn.svg"/>
  </div>
  <div class="event-orderable__title">
    {{ 'components.order.event-orderable.title' | translate }}
  </div>
  <div class="event-orderable__subtitle">
    {{ 'components.order.event-orderable.subtitle' | translate }}
  </div>
  <div class="event-orderable__cancel" (click)="dismiss()">
    {{ 'pages.settings.confirm.cancel' | translate }}
  </div>
</div>