<div class="location-box">
  <div class="location-box__icon"></div>
  <div class="location-box__header">{{ 'components.location-selector.can_get_location' | translate }}</div>
  <div class="location-box__subheader">{{ 'components.location-selector.nearby_events' | translate }}</div>
  <div class="location-box__buttons">
    <div class="btn" (click)="findLocation()">{{ 'components.location-selector.sure' | translate }}</div>
    <div class="btn btn-no" (click)="closeModal()">{{ 'dialog.apprate.cancelButtonLabel' | translate }}</div>
  </div>
  <div class="location-box__note">
    {{ 'components.location-selector.provide_relevant_events' | translate }}
  </div>
</div>